<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Ubah Staff
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer ref="formRules"
        v-slot="{ invalid }">
        <b-row>
          <b-col md="8">
          <!-- form -->
            <b-form>
              <b-row>
                <b-col
                  class="pb-2"
                  md="12"
                >
                  <h3 class="mb-2 text-10">
                    Profil
                  </h3>
                  <b-form-row>
                    <b-col cols="4">
                      <div class="text-left mr-md-1">
                        <b-avatar
                          size="56px"
                          :src="imageFile ? fileUrl(imageFile) : imageInitialFile"
                        />
                      </div>
                    </b-col>
                    <b-col cols="8">
                      <b-form-group
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Foto profil"
                          :rules="imageInitialFile ? '' : 'required'"
                        >
                          <b-form-file
                            v-model="imageFile"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              imageInitialFile
                                ? imageInitialFile.split('/').pop()
                                : `Upload Foto kamu`
                            "
                            drop-placeholder="Upload foto kamu"
                            accept="image/jpeg, image/png, image/gif"
                            @change="uploadImage($event)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Nama Lengkap <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Lengkap"
                      rules="required|min:3|max:50|alpha_spaces"
                    >
                      <b-form-input
                        v-model="fullname"
                        :formatter="capitalize"
                        placeholder="Masukkan Nama Lengkap"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Username <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      :rules="{ regex: /^[a-zA-Z0-9 ]*$/, required: true, max: 25 }"
                    >
                      <b-form-input
                        v-model="username"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Masukkan Username"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Jenis Kelamin <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Jenis kelamin"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="gender"
                        class="mt-50"
                        :options="genderOptions"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Tanggal lahir <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggal lahir"
                      :rules="'required'"
                    >
                      <flat-pickr
                        v-model="birthDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'j F Y',
                          dateFormat: 'Y-m-d',
                        }"
                        placeholder="Pilih Tanggal"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Status Pernikahan <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Status Pernikahan"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="maritalStatus"
                        class="mt-50"
                        :options="maritalOptions"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <h3 class="my-2 text-10">
                    Alamat Asal
                  </h3>
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Lokasi <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Lokasi"
                      rules="required"
                    >
                      <v-select
                        v-model="subdistrictId"
                        :reduce="option => option.subdistrict_id"
                        :options="listSubdistrict"
                        :state="
                          errors.length > 0 || submitErrors.province_id
                            ? false
                            : null
                        "
                        placeholder="Ketik untuk mencari..."
                        @search="(search) => search.length > 0 ? onSearchSubdistrict(search) : ''"
                      >
                        <template
                          #selected-option-container="{ option }"
                        >
                          <div class="vs__selected">
                            {{ `${option.subdistrict_name}, ${option.district_name}, ${option.regency_name}, ${option.province_name}` }}
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{
                        errors[0] || submitErrors.province_id
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Alamat Lengkap <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Alamat"
                      rules="required|min:5"
                    >
                      <b-form-textarea
                        v-model="address"
                        placeholder="Masukkan Alamat Lengkap"
                        :state="
                          errors.length > 0 || submitErrors.address ? false : null
                        "
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.address
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <h3 class="my-2 text-10">
                    Data Komerce
                  </h3>
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Divisi <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Divisi"
                      rules="required"
                    >
                      <v-select
                        v-model="division"
                        label="division_name"
                        :reduce="option => option"
                        :options="listDivision"
                        placeholder="Ketik untuk mencari..."
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Posisi <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Posisi"
                      rules="required"
                    >
                      <v-select
                        v-model="position"
                        label="position_name"
                        :reduce="option => option"
                        :options="listPosition"
                        placeholder="Ketik untuk mencari..."
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Tanggal bergabung <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggal Bergabung"
                      :rules="'required'"
                    >
                      <flat-pickr
                        v-model="joinDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'j F Y',
                          dateFormat: 'Y-m-d',
                        }"
                        placeholder="Pilih Tanggal"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Working Space <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Working Space"
                      rules="required"
                    >
                      <v-select
                        v-model="workingSpace"
                        label="working_space_name"
                        :reduce="option => option"
                        :options="listWorkingSpace"
                        placeholder="Ketik untuk mencari..."
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <h3 class="my-2 text-10">
                    Kontak
                  </h3>
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      No HP <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="No Hp"
                      rules="required|min:9|max:15"
                    >
                      <cleave
                        v-model="phone"
                        class="form-control"
                        :options="options.phone"
                        placeholder="Masukkan No HP"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Email <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="email"
                        type="email"
                        placeholder="Masukkan Email"
                        :state="
                          errors.length > 0 || submitErrors.email ? false : null
                        "
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.email
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <h3 class="my-2 text-10">
                    Akun Bank
                  </h3>
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Nama Bank <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Bank"
                      :rules="'required'"
                    >
                      <v-select
                        v-model="bankCode"
                        label="name"
                        :reduce="option => option"
                        :options="listBank"
                        :filterable="true"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Ketik untuk mencari..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      No Rekening <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="No rekening"
                      :rules="'required|numeric'"
                    >
                      <b-form-input
                        v-model="bankNo"
                        placeholder="Masukkan No Rekening"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Nama Pemilik Rekening <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Nama pemilik rekening"
                      rules="required|alpha_spaces"
                    >
                      <b-form-input
                        v-model="bankOwnerName"
                        type="text"
                        :formatter="capitalize"
                        placeholder="Masukkan Nama Pemilik Rekening"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                >
                  <h3 class="my-2 text-10">
                    Password
                  </h3>
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Password <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      :rules="'min:8'"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="password"
                          :type="showPassword ? 'text' : 'password'"
                          placeholder="Password minimal 8 karakter"
                          :state="errors.length > 0 ? false : null"
                        />
                        <b-input-group-append is-text>
                          <span
                            class="k-eye cursor-pointer"
                            @click="showPassword = !showPassword"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Konfirmasi Password <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Konfirmasi password"
                      :rules="'confirmed:Password'"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="confirmationPassword"
                          :state="errors.length > 0 ? false : null"
                          :type="showConfirmPassword ? 'text' : 'password'"
                          placeholder="Konfirmasi password"
                        />
                        <b-input-group-append is-text>
                          <span
                            class="k-eye cursor-pointer"
                            @click="showConfirmPassword = !showConfirmPassword"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click="$router.back()"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary w-[200px]"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BCol,
  BFormFile,
  BOverlay,
  BInputGroup,
  BFormRadioGroup,
} from 'bootstrap-vue'
import {
  required,
  min,
  minValue,
  max,
  alpha_spaces,
  numeric,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { capitalize } from '@/libs/helpers'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
    BFormFile,
    BInputGroup,
    BFormRadioGroup,
    BOverlay,
    flatPickr,
    vSelect,
    Cleave,
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      capitalize,

      required,
      min,
      max,
      minValue,
      alpha_spaces,
      numeric,

      fullname: '',
      username: '',
      gender: '',
      birthDate: null,
      maritalStatus: null,

      subdistrictId: null,
      address: '',

      division: null,
      position: null,
      joinDate: null,
      workingSpace: null,

      phone: '',
      email: '',

      bankCode: '',
      bankNo: '',
      bankOwnerName: '',

      password: '',
      confirmationPassword: '',

      subdistrictKeyword: '',

      imageFile: null,
      imageInitialFile: null,
      banks: [],

      listSubdistrict: [],
      listBank: [],
      listDivision: [],
      listPosition: [],
      listWorkingSpace: [],
      detailStaff: {},

      maritalOptions: [
        { text: 'Belum Menikah', value: 0 },
        { text: 'Menikah', value: 1 },
      ],
      genderOptions: [
        { text: 'Laki-laki', value: 'L' },
        { text: 'Perempuan', value: 'P' },
      ],
      teamMemberOptions: [
        { label: '0 - 4 orang', value: '0 - 4' },
        { label: '5 - 19 orang', value: '5 - 19' },
        { label: '20 - 99 orang', value: '20 - 99' },
        { label: '> 100 orang', value: '> 100' },
      ],
      referenceOptions: [
        { label: 'Media Sosial', value: 'Media Sosial' },
        { label: 'Google search', value: 'Mesin Pencari Google' },
        { label: 'Rekomendasi Teman', value: 'Rekomendasi Teman' },
        { label: 'Acara / Event', value: 'Acara' },
      ],
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID',
        },
      },
      alertError,
      alertSuccess,
      showPassword: false,
      showConfirmPassword: false,
    }
  },
  async mounted() {
    this.getDetailStaff()
    this.getListBank()
    this.getListDivision()
    this.getListPosition()
    this.getListWorkingSpace()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const formData = new FormData()
      formData.append('full_name', this.fullname)
      formData.append('username', this.username)
      formData.append('gender', this.gender)
      formData.append('birth_date', this.birthDate)
      formData.append('marital_status', this.maritalStatus)

      formData.append('subdistrict_id', (!Number.isInteger(this.subdistrictId)) ? this.detailStaff.subdistrict_id : this.subdistrictId)
      formData.append('address', this.address)

      formData.append('division_id', this.division.id ? this.division.id : this.detailStaff.division_id)
      formData.append('division_name', this.division.division_name ? this.division.division_name : this.detailStaff.division_name)
      formData.append('position_id', this.position.id ? this.position.id : this.detailStaff.position_id)
      formData.append('position_name', this.position.position_name ? this.position.position_name : this.detailStaff.position_name)
      formData.append('join_date', this.joinDate)
      formData.append('office_id', this.workingSpace.id ? this.workingSpace.id : this.detailStaff.office_id)
      formData.append('office_name', this.workingSpace.working_space_name ? this.workingSpace.working_space_name : this.detailStaff.office_name)

      formData.append('phone_number', this.phone)
      formData.append('email', this.email)

      formData.append('bank_code', this.bankCode.code)
      formData.append('bank_name', this.bankCode.name)
      formData.append('bank_owner_no', this.bankNo)
      formData.append('bank_owner_name', this.bankOwnerName)

      if (this.confirmationPassword !== '') formData.append('confirmation_password', this.confirmationPassword)
      if (this.password !== '') formData.append('password', this.password)

      if (this.imageFile) formData.append('photo_profile', this.imageFile)

      const url = `v1/staffs/${this.$route.params.id}/update`
      await komtimAxiosIns
        .put(url, formData)
        .then(() => {
          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)

          this.$router.push({ name: 'staff' })
        })
        .catch(error => {
          this.alertError(error)

          if (error.response.status === 422) {
            this.submitErrors = Object.fromEntries(
              Object.entries(error.response.data.data).map(
                ([key, value]) => [key, value[0]],
              ),
            )
          }
        })
    },
    async getDetailStaff() {
      const url = `v1/staffs/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailStaff = await data

          this.loadForm()
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    async getListBank() {
      this.offset = 0
      const url = 'v1/bank/available-bank'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listBank = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListDivision() {
      const params = 'status=true'
      const url = `v1/divisions/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listDivision = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListPosition() {
      const params = 'status=true'
      const url = `v1/positions/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listPosition = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListWorkingSpace() {
      const params = 'status=true'
      const url = `v1/working_spaces/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listWorkingSpace = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListSubdistrict(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&keyword=${keyword}`
      const url = `v1/region/villages?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listSubdistrict = data
          this.listSubdistrict = this.listSubdistrict.map(item => {
            const result = {
              ...item,
              label: `${item.subdistrict_name}, ${item.district_name}, ${item.regency_name}, ${item.province_name}`,
            }
            return result
          })
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchSubdistrict: _.debounce((search, it) => {
      it.getListSubdistrict(search)
    }, 500),
    onSearchSubdistrict(search) {
      this.searchSubdistrict(search, this)
    },
    uploadImage(e) {
      const img = e.target.value
      const fileExtension = img.substr((img.lastIndexOf('.') + 1))
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG dan JPEG')
        this.imageFile = null
      } else {
        this.imageFile = img
      }
    },
    fileUrl: file => (file ? URL.createObjectURL(file) : null),
    loadForm() {
      this.fullname = this.detailStaff.full_name
      this.username = this.detailStaff.username
      this.gender = this.detailStaff.gender
      this.birthDate = this.detailStaff.birth_date
      this.maritalStatus = this.detailStaff.marital_status

      this.subdistrictId = {
        district_name: this.detailStaff.district_name,
        province_name: this.detailStaff.province_name,
        regency_name: this.detailStaff.regency_name,
        subdistrict_id: this.detailStaff.subdistrict_id,
        subdistrict_name: this.detailStaff.subdistrict_name,
        label: `${this.detailStaff.subdistrict_name}, ${this.detailStaff.district_name}, ${this.detailStaff.regency_name}, ${this.detailStaff.province_name}`,
      }
      this.address = this.detailStaff.address

      this.division = this.detailStaff.division_name
      this.position = this.detailStaff.position_name
      this.joinDate = this.detailStaff.join_date
      this.workingSpace = this.detailStaff.office_name

      this.phone = this.detailStaff.phone_number
      this.email = this.detailStaff.email

      this.bankCode = this.detailStaff.bank_name
      this.bankNo = this.detailStaff.bank_owner_no
      this.bankOwnerName = this.detailStaff.bank_owner_name

      if (this.detailStaff.photo_profile_url) this.imageInitialFile = this.detailStaff.photo_profile_url
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
